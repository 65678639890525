import React from 'react'

const ConsultationModal = () => {
  return (
    <>
    <div className="container">
      <div className="verify_email_box">
        <div className="email_box_v">
          <img
            src={require("../assets/img/greenchecked.png")}
            className="img-fluid"
          />
          <p>Form Submitted Successfully</p>
        </div>
      </div>
    </div>
  </>
  )
}

export default ConsultationModal
