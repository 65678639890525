import React from "react";

const CompleteConsultationCheck = () => {
  return (
    <>
      <div className="container">
        <div className="verify_email_box">
          <div className="email_box_v">
            <img
              src={require("../assets/img/greenchecked.png")}
              className="img-fluid"
            />
            <p>Form has been Completed.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteConsultationCheck;
